import styled from '@emotion/styled';
import { pxToRem } from '../theme/Theme';

export default styled.div`
  ${({ theme }) => theme.fonts.september.bold};
  font-size: ${pxToRem(24)};
  line-height: 120%;
  margin: 20px auto 15px;
  width: 100%;
  color: ${({ theme }) => theme.colors.bahamaBlue};
  padding: 0 14px;
  height: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  gap: 15px;
  .stars {
    display: flex;
    flex-direction: row;
    justify-self: stretch;
    justify-content: space-between;
    flex-grow: 1;
  }
`;
