import React, { useMemo } from 'react';
import { useRef } from 'react';
import Counter from '../app/helpers/counter';
import StyledScore from './Score.style';

const counter = new Counter();

const Star = ({ full, height = 30 }) => {
  const id = useRef(counter.get((i) => `inStar-${i}`)).current;
  return (
    <svg
      width={(height / 30) * 32}
      height={height}
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id={id}>
        <path d="m16 4 4 8 8 1-6 5 1 9-7-4-7 4 1-9-6-5 8-1z" fill="#fff" />
      </mask>
      <g fill="#5CC563">
        <path d="m16 21.29 5.663 3.17-1.266-6.364 4.764-4.407-6.444-.764L16 7.033l-2.717 5.892-6.444.764 4.764 4.407-1.266 6.364Zm0 3.057L6.596 29.61l2.1-10.571-7.913-7.317 10.702-1.27L16 .667l4.515 9.786 10.702 1.27-7.913 7.317 2.1 10.57L16 24.348Z" />
        <g mask={`url(#${id})`}>
          <rect
            height="30"
            width="19"
            transform={`matrix(${full} 0 0 1 6.5 0)`}
          />
        </g>
      </g>
    </svg>
  );
};

export const Stars = ({ stars, maxStars = 5, height = 30 }) => {
  const c = useMemo(() => {
    const res = [];
    for (let i = 0; i < maxStars; i++) {
      res.push(Math.min(1, stars - i));
    }
    return res;
  }, [stars]);
  return (
    <span className="stars">
      {c.map((full, ix) => (
        <Star full={full} key={ix} height={height} />
      ))}
    </span>
  );
};
